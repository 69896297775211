import { RequestTaskActionProcessDTO, RequestTaskDTO } from 'pmrv-api';

export const cancelActionMap: Partial<
  Record<RequestTaskDTO['type'], RequestTaskActionProcessDTO['requestTaskActionType']>
> = {
  AVIATION_ACCOUNT_CLOSURE_SUBMIT: 'AVIATION_ACCOUNT_CLOSURE_CANCEL_APPLICATION',
  AVIATION_DRE_UKETS_APPLICATION_SUBMIT: 'AVIATION_DRE_CANCEL_APPLICATION',
  EMP_VARIATION_UKETS_APPLICATION_SUBMIT: 'EMP_VARIATION_CANCEL_APPLICATION',
  EMP_VARIATION_CORSIA_APPLICATION_SUBMIT: 'EMP_VARIATION_CANCEL_APPLICATION',
  EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT: 'EMP_VARIATION_CANCEL_APPLICATION',
  EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT: 'EMP_VARIATION_CANCEL_APPLICATION',
};
